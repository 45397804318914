export const productInfo = {
  '1765069357156':
    // acuvue oasys 1 day with hydraluxe
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' }
      ],
      base_curve_values: [
        { value: '8.5', label: '8.5' },
        { value: '9.0', label: '9.0' },
      ],
      diameter: '14.3',
    },
  '2620861448292':
    {
      // acuvue moist
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' }
      ],
      base_curve_values: [
        { value: '8.5', label: '8.5' },
        { value: '9.0', label: '9.0' },
      ],
      diameter: '14.2',
    },
  '1765114970212':
    {
    // DAILIES AquaComfort Plus
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
        { value: '-12.50', label: '-12.50' },
        { value: '-13.00', label: '-13.00' },
        { value: '-13.50', label: '-13.50' },
        { value: '-14.00', label: '-14.00' },
        { value: '-14.50', label: '-14.50' },
        { value: '-15.00', label: '-15.00' },
      ],

      base_curve_values: [
        { value: '8.7', label: '8.7' },
      ],

      diameter: '14.0',
    },
  '1765078499428':
    {
      // Acuvue Oasys with Hydraclear Plus 6pk
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],
      base_curve_values: [
        { value: '8.4', label: '8.4' },
        { value: '8.8', label: '8.8' },
      ],
      diameter: '14.0',
    },
  '2620910534756':
    {
      // DAILIES TOTAL1 90pk*
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' }
      ],
      base_curve_values: [
        { value: '8.5', label: '8.5' },
      ],
      diameter: '14.1',
    },
  '1765111332964':
    {
      // Biotrue ONEday 90pk
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],

      base_curve_values: [
        { value: '8.6', label: '8.6' },
      ],

      diameter: '14.2'
    },
  '3408938401892':
    {
      // Biotrue ONEday for Presbyopia 90pk
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.25', label: '-6.25' },
        { value: '-6.50', label: '-6.50' },
        { value: '-6.75', label: '-6.75' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.25', label: '-7.25' },
        { value: '-7.50', label: '-7.50' },
        { value: '-7.75', label: '-7.75' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.25', label: '-8.25' },
        { value: '-8.50', label: '-8.50' },
        { value: '-8.75', label: '-8.75' },
        { value: '-9.00', label: '-9.00' }
      ],
      base_curve_values: [
        { value: '8.6', label: '8.6' },
      ],
      add_power: [
        { value: '1.50/low', label: '1.50/low' },
        { value: '2.50/high', label: '2.50/high' },
      ],
      diameter: '14.2'
    },
  '4860500377700':
    // acuvue oasys 1 day with hydraluxe
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],
      base_curve_values: [
        { value: '8.5', label: '8.5' },
        { value: '9.0', label: '9.0' },
      ],
      diameter: '14.3',
    },
  '1765066866788':
    // acuvue moist
    {
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],

      base_curve_values: [
        { value: '8.5', label: '8.5' },
        { value: '9.0', label: '9.0' },
      ],

      diameter: '14.2',
    },
  '3340728631396':
    // DAILIES AquaComfort Plus
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
        { value: '-12.50', label: '-12.50' },
        { value: '-13.00', label: '-13.00' },
        { value: '-13.50', label: '-13.50' },
        { value: '-14.00', label: '-14.00' },
        { value: '-14.50', label: '-14.50' },
        { value: '-15.00', label: '-15.00' },
      ],

      base_curve_values: [{ value: '8.7', label: '8.7' }],

      diameter: '14.0',
    },
  '3408967008356':
    // Biotrue ONEday 30pk
    {
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' }
      ],

      base_curve_values: [{ value: '8.6', label: '8.6' }],

      diameter: '14.2',
    },
  '1765084102756':
    // Air Optix Plus Hydraglyde
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.25', label: '-6.25' },
        { value: '-6.50', label: '-6.50' },
        { value: '-6.75', label: '-6.75' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.25', label: '-7.25' },
        { value: '-7.50', label: '-7.50' },
        { value: '-7.75', label: '-7.75' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],

      base_curve_values: [{ value: '8.6', label: '8.6' }],

      diameter: '14.2',
    },
  '1765097439332':
    // Biofinity 6pk
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],

      base_curve_values: [{ value: '8.6', label: '8.6' }],

      diameter: '14.0',
    },
  '1765082136676':
    // Acuvue Vita
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],

      base_curve_values: [
        { value: '8.4', label: '8.4' },
        { value: '8.8', label: '8.8' },
      ],

      diameter: '14.0',
    },
  '3409786896484':
    // Acuvue Oasys with Hydraclear Plus 12pk
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],

      base_curve_values: [
        { value: '8.4', label: '8.4' },
        { value: '8.8', label: '8.8' },
      ],

      diameter: '14.0',
    },
  '1765074927716':
    // Acuvue 2 6pk
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
        { value: '-10.50', label: '-10.50' },
        { value: '-11.00', label: '-11.00' },
        { value: '-11.50', label: '-11.50' },
        { value: '-12.00', label: '-12.00' },
      ],

      base_curve_values: [
        { value: '8.3', label: '8.3' },
        { value: '8.7', label: '8.7' },
      ],

      diameter: '14.0',
    },
  '1765092720740':
    // Air Optix Night & Day Aqua 6pk*
    {
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.25', label: '-6.25' },
        { value: '-6.50', label: '-6.50' },
        { value: '-6.75', label: '-6.75' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.25', label: '-7.25' },
        { value: '-7.50', label: '-7.50' },
        { value: '-7.75', label: '-7.75' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
      ],

      base_curve_values: [
        { value: '8.4', label: '8.4' },
        { value: '8.6', label: '8.6' },
      ],

      diameter: '13.8',
    },
  '1765100453988':
    // Biofinity toric 6pk
    {
      eye_powers: [
        { value: '+8.00', label: '+8.00' },
        { value: '+7.50', label: '+7.50' },
        { value: '+7.00', label: '+7.00' },
        { value: '+6.50', label: '+6.50' },
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
        { value: '-9.50', label: '-9.50' },
        { value: '-10.00', label: '-10.00' },
      ],

      base_curve_values: [{ value: '8.7', label: '8.7' }],

      diameter: '14.5',
      cylinder: [
        { value: '-0.75', label: '-0.75' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.25', label: '-2.25' },
      ],
      axis: [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
        { value: '70', label: '70' },
        { value: '80', label: '80' },
        { value: '90', label: '90' },
        { value: '100', label: '100' },
        { value: '110', label: '110' },
        { value: '120', label: '120' },
        { value: '130', label: '130' },
        { value: '140', label: '140' },
        { value: '150', label: '150' },
        { value: '160', label: '160' },
        { value: '170', label: '170' },
        { value: '180', label: '180' },
      ],
    },
  '1765080399972':
    // Acuvue Oasys for Astigmatism 6pk
    {
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
      ],

      base_curve_values: [{ value: '8.6', label: '8.6' }],

      diameter: '14.5',
      cylinder: [
        { value: '-0.75', label: '-0.75' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.75', label: '-2.75' },
      ],
      axis: [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
        { value: '70', label: '70' },
        { value: '80', label: '80' },
        { value: '90', label: '90' },
        { value: '100', label: '100' },
        { value: '110', label: '110' },
        { value: '120', label: '120' },
        { value: '130', label: '130' },
        { value: '140', label: '140' },
        { value: '150', label: '150' },
        { value: '160', label: '160' },
        { value: '170', label: '170' },
        { value: '180', label: '180' },
      ],
    },
  '1765072797796':
    // 1-Day Acuvue Moist for Astigmatism 30pk
    {
      eye_powers: [
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
      ],

      base_curve_values: [{ value: '8.5', label: '8.5' }],

      diameter: '14.5',
      cylinder: [
        { value: '-0.75', label: '-0.75' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.25', label: '-2.25' },
      ],
      axis: [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
        { value: '70', label: '70' },
        { value: '80', label: '80' },
        { value: '90', label: '90' },
        { value: '100', label: '100' },
        { value: '110', label: '110' },
        { value: '120', label: '120' },
        { value: '130', label: '130' },
        { value: '140', label: '140' },
        { value: '150', label: '150' },
        { value: '160', label: '160' },
        { value: '170', label: '170' },
        { value: '180', label: '180' },
      ],
    },
  '3344340287588':
  // 1-Day Acuvue Moist for Astigmatism 90pk
    {
      eye_powers: [
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' },
      ],

      base_curve_values: [{ value: '8.5', label: '8.5' }],
      diameter: [{value: '14.5', label: '14.5'}],
      cylinder: [
        { value: '-0.75', label: '-0.75' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.25', label: '-2.25' },
      ],
      axis: [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
        { value: '70', label: '70' },
        { value: '80', label: '80' },
        { value: '90', label: '90' },
        { value: '100', label: '100' },
        { value: '110', label: '110' },
        { value: '120', label: '120' },
        { value: '130', label: '130' },
        { value: '140', label: '140' },
        { value: '150', label: '150' },
        { value: '160', label: '160' },
        { value: '170', label: '170' },
        { value: '180', label: '180' },
      ],
    },
  '3338889855076':
    {
      // 1-Day Acuvue Moist Multifocal 30pk
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.25', label: '-6.25' },
        { value: '-6.50', label: '-6.50' },
        { value: '-6.75', label: '-6.75' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.25', label: '-7.25' },
        { value: '-7.50', label: '-7.50' },
        { value: '-7.75', label: '-7.75' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.25', label: '-8.25' },
        { value: '-8.50', label: '-8.50' },
        { value: '-8.75', label: '-8.75' },
        { value: '-9.00', label: '-9.00' }
      ],
      base_curve_values: [
        { value: '8.4', label: '8.4' },
      ],
      add_power: [
        { value: '1.25/low', label: '1.25/low' },
        { value: '1.75/mid', label: '1.75/mid' },
        { value: '2.50/high', label: '2.50/high' },
      ],
      diameter: '14.3'
    },
  '3341075808356':
    {
      // 1-Day Acuvue Moist Multifocal 30pk
      eye_powers: [
        { value: '+6.00', label: '+6.00' },
        { value: '+5.75', label: '+5.75' },
        { value: '+5.50', label: '+5.50' },
        { value: '+5.25', label: '+5.25' },
        { value: '+5.00', label: '+5.00' },
        { value: '+4.75', label: '+4.75' },
        { value: '+4.50', label: '+4.50' },
        { value: '+4.25', label: '+4.25' },
        { value: '+4.00', label: '+4.00' },
        { value: '+3.75', label: '+3.75' },
        { value: '+3.50', label: '+3.50' },
        { value: '+3.25', label: '+3.25' },
        { value: '+3.00', label: '+3.00' },
        { value: '+2.75', label: '+2.75' },
        { value: '+2.50', label: '+2.50' },
        { value: '+2.25', label: '+2.25' },
        { value: '+2.00', label: '+2.00' },
        { value: '+1.75', label: '+1.75' },
        { value: '+1.50', label: '+1.50' },
        { value: '+1.25', label: '+1.25' },
        { value: '+1.00', label: '+1.00' },
        { value: '+0.75', label: '+0.75' },
        { value: '+0.50', label: '+0.50' },
        { value: '+0.25', label: '+0.25' },
        { value: '0', label: '0' },
        { value: '-0.25', label: '-0.25' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.25', label: '-6.25' },
        { value: '-6.50', label: '-6.50' },
        { value: '-6.75', label: '-6.75' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.25', label: '-7.25' },
        { value: '-7.50', label: '-7.50' },
        { value: '-7.75', label: '-7.75' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.25', label: '-8.25' },
        { value: '-8.50', label: '-8.50' },
        { value: '-8.75', label: '-8.75' },
        { value: '-9.00', label: '-9.00' }
      ],
      base_curve_values: [
        { value: '8.4', label: '8.4' },
      ],
      add_power: [
        { value: '1.25/low', label: '1.25/low' },
        { value: '1.75/mid', label: '1.75/mid' },
        { value: '2.50/high', label: '2.50/high' },
      ],
      diameter: '14.3'
    },
    '3409278664804':
    {
      // 1-Day Acuvue Define 30pk"
      eye_powers: [

        { value: '+1.00', label: '+1.00' },
        { value: '+0.50', label: '+0.50' },
        { value: '0', label: '0' },
        { value: '-0.50', label: '-0.50' },
        { value: '-0.75', label: '-0.75' },
        { value: '-1.00', label: '-1.00' },
        { value: '-1.25', label: '-1.25' },
        { value: '-1.50', label: '-1.50' },
        { value: '-1.75', label: '-1.75' },
        { value: '-2.00', label: '-2.00' },
        { value: '-2.25', label: '-2.25' },
        { value: '-2.50', label: '-2.50' },
        { value: '-2.75', label: '-2.75' },
        { value: '-3.00', label: '-3.00' },
        { value: '-3.25', label: '-3.25' },
        { value: '-3.50', label: '-3.50' },
        { value: '-3.75', label: '-3.75' },
        { value: '-4.00', label: '-4.00' },
        { value: '-4.25', label: '-4.25' },
        { value: '-4.50', label: '-4.50' },
        { value: '-4.75', label: '-4.75' },
        { value: '-5.00', label: '-5.00' },
        { value: '-5.25', label: '-5.25' },
        { value: '-5.50', label: '-5.50' },
        { value: '-5.75', label: '-5.75' },
        { value: '-6.00', label: '-6.00' },
        { value: '-6.50', label: '-6.50' },
        { value: '-7.00', label: '-7.00' },
        { value: '-7.50', label: '-7.50' },
        { value: '-8.00', label: '-8.00' },
        { value: '-8.50', label: '-8.50' },
        { value: '-9.00', label: '-9.00' }
      ],
      base_curve_values: [
        { value: '8.5', label: '8.5' },
      ],
      color: [
        { value: 'Accent Style', label: 'Accent Style' },
        { value: 'Natural Shine', label: 'Natural Shine' },
        { value: 'Vivid Style', label: 'Vivid Style' },
      ],
      diameter: '14.2'
    },
    '3409322016868':
      // Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 30pk
      {
        eye_powers: [
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' }
        ],

        base_curve_values: [{ value: '8.5', label: '8.5' }],

        diameter: '14.3',
        cylinder: [
          { value: '-0.75', label: '-0.75' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.25', label: '-2.25' },
        ],
        axis: [
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '40', label: '40' },
          { value: '50', label: '50' },
          { value: '60', label: '60' },
          { value: '70', label: '70' },
          { value: '80', label: '80' },
          { value: '90', label: '90' },
          { value: '100', label: '100' },
          { value: '110', label: '110' },
          { value: '120', label: '120' },
          { value: '130', label: '130' },
          { value: '140', label: '140' },
          { value: '150', label: '150' },
          { value: '160', label: '160' },
          { value: '170', label: '170' },
          { value: '180', label: '180' },
        ],
      },
    '7165851992164':
      // Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 30pk
      {
        eye_powers: [
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' }
        ],

        base_curve_values: [{ value: '8.5', label: '8.5' }],

        diameter: '14.3',
        cylinder: [
          { value: '-0.75', label: '-0.75' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.25', label: '-2.25' },
        ],
        axis: [
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '40', label: '40' },
          { value: '50', label: '50' },
          { value: '60', label: '60' },
          { value: '70', label: '70' },
          { value: '80', label: '80' },
          { value: '90', label: '90' },
          { value: '100', label: '100' },
          { value: '110', label: '110' },
          { value: '120', label: '120' },
          { value: '130', label: '130' },
          { value: '140', label: '140' },
          { value: '150', label: '150' },
          { value: '160', label: '160' },
          { value: '170', label: '170' },
          { value: '180', label: '180' },
        ],
      },
    '7173256249444':
      // Acuvue Oasys Max 1-Day 30pk
      {
        eye_powers: [
          { value: '+8.00', label: '+8.00' },
          { value: '+7.50', label: '+7.50' },
          { value: '+7.00', label: '+7.00' },
          { value: '+6.50', label: '+6.50' },
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '0', label: '0' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' },
          { value: '-9.50', label: '-9.50' },
          { value: '-10.00', label: '-10.00' },
          { value: '-10.50', label: '-10.50' },
          { value: '-11.00', label: '-11.00' },
          { value: '-11.50', label: '-11.50' },
          { value: '-12.00', label: '-12.00' }
        ],
        base_curve_values: [
          { value: '8.5', label: '8.5' },
          { value: '9.0', label: '9.0' },
        ],
        diameter: '14.3',
      },
    '2620883206244':
      // Acuvue Oasys with Hydraclear Plus 24pk
      {
        eye_powers: [
          { value: '+8.00', label: '+8.00' },
          { value: '+7.50', label: '+7.50' },
          { value: '+7.00', label: '+7.00' },
          { value: '+6.50', label: '+6.50' },
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' },
          { value: '-9.50', label: '-9.50' },
          { value: '-10.00', label: '-10.00' },
          { value: '-10.50', label: '-10.50' },
          { value: '-11.00', label: '-11.00' },
          { value: '-11.50', label: '-11.50' },
          { value: '-12.00', label: '-12.00' },
        ],

        base_curve_values: [
          { value: '8.4', label: '8.4' },
          { value: '8.8', label: '8.8' },
        ],

        diameter: '14.0',
      },
    '4801375567972':
      // Acuvue Oasys with Transitions 6pk
      {
        eye_powers: [
          { value: '+8.00', label: '+8.00' },
          { value: '+7.50', label: '+7.50' },
          { value: '+7.00', label: '+7.00' },
          { value: '+6.50', label: '+6.50' },
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' },
          { value: '-9.50', label: '-9.50' },
          { value: '-10.00', label: '-10.00' },
          { value: '-10.50', label: '-10.50' },
          { value: '-11.00', label: '-11.00' },
          { value: '-11.50', label: '-11.50' },
          { value: '-12.00', label: '-12.00' },
        ],

        base_curve_values: [
          { value: '8.4', label: '8.4' },
          { value: '8.8', label: '8.8' },
        ],

        diameter: '14.0',
      },
    '3409889198180':
      // Acuvue Vita 6pk
      {
        eye_powers: [
          { value: '+8.00', label: '+8.00' },
          { value: '+7.50', label: '+7.50' },
          { value: '+7.00', label: '+7.00' },
          { value: '+6.50', label: '+6.50' },
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' },
          { value: '-9.50', label: '-9.50' },
          { value: '-10.00', label: '-10.00' },
          { value: '-10.50', label: '-10.50' },
          { value: '-11.00', label: '-11.00' },
          { value: '-11.50', label: '-11.50' },
          { value: '-12.00', label: '-12.00' },
        ],

        base_curve_values: [
          { value: '8.4', label: '8.4' },
          { value: '8.8', label: '8.8' },
        ],

        diameter: '14.0',
      },
    '3379060179044':
      // Acuvue Vita for Astigmatism 6pk
      {
        eye_powers: [
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' }
        ],

        base_curve_values: [{ value: '8.6', label: '8.6' }],

        diameter: '14.5',
        cylinder: [
          { value: '-0.75', label: '-0.75' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.25', label: '-2.25' },
        ],
        axis: [
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '40', label: '40' },
          { value: '50', label: '50' },
          { value: '60', label: '60' },
          { value: '70', label: '70' },
          { value: '80', label: '80' },
          { value: '90', label: '90' },
          { value: '100', label: '100' },
          { value: '110', label: '110' },
          { value: '120', label: '120' },
          { value: '130', label: '130' },
          { value: '140', label: '140' },
          { value: '150', label: '150' },
          { value: '160', label: '160' },
          { value: '170', label: '170' },
          { value: '180', label: '180' },
        ],
      },
    '3408913530980':
      // Biotrue ONEday Astigmatism 30pk
      {
        eye_powers: [
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' }
        ],

        base_curve_values: [{ value: '8.4', label: '8.4' }],

        diameter: '14.5',
        cylinder: [
          { value: '-0.75', label: '-0.75' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.75', label: '-2.75' },
        ],
        axis: [
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '40', label: '40' },
          { value: '50', label: '50' },
          { value: '60', label: '60' },
          { value: '70', label: '70' },
          { value: '80', label: '80' },
          { value: '90', label: '90' },
          { value: '100', label: '100' },
          { value: '110', label: '110' },
          { value: '120', label: '120' },
          { value: '130', label: '130' },
          { value: '140', label: '140' },
          { value: '150', label: '150' },
          { value: '160', label: '160' },
          { value: '170', label: '170' },
          { value: '180', label: '180' },
        ],
      },
    '3402116235364':
      // Biotrue ONEday Astigmatism 90pk
      {
        eye_powers: [
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' }
        ],

        base_curve_values: [{ value: '8.4', label: '8.4' }],

        diameter: '14.5',
        cylinder: [
          { value: '-0.75', label: '-0.75' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.75', label: '-2.75' },
        ],
        axis: [
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '40', label: '40' },
          { value: '50', label: '50' },
          { value: '60', label: '60' },
          { value: '70', label: '70' },
          { value: '80', label: '80' },
          { value: '90', label: '90' },
          { value: '100', label: '100' },
          { value: '110', label: '110' },
          { value: '120', label: '120' },
          { value: '130', label: '130' },
          { value: '140', label: '140' },
          { value: '150', label: '150' },
          { value: '160', label: '160' },
          { value: '170', label: '170' },
          { value: '180', label: '180' },
        ],
      },
    "6549011464292": {
        eye_powers: [
          { value: "+6.00", label: "+6.00" },
          { value: "+5.75", label: "+5.75" },
          { value: "+5.50", label: "+5.50" },
          { value: "+5.25", label: "+5.25" },
          { value: "+5.00", label: "+5.00" },
          { value: "+4.75", label: "+4.75" },
          { value: "+4.50", label: "+4.50" },
          { value: "+4.25", label: "+4.25" },
          { value: "+4.00", label: "+4.00" },
          { value: "+3.75", label: "+3.75" },
          { value: "+3.50", label: "+3.50" },
          { value: "+3.25", label: "+3.25" },
          { value: "+3.00", label: "+3.00" },
          { value: "+2.75", label: "+2.75" },
          { value: "+2.50", label: "+2.50" },
          { value: "+2.25", label: "+2.25" },
          { value: "+2.00", label: "+2.00" },
          { value: "+1.75", label: "+1.75" },
          { value: "+1.50", label: "+1.50" },
          { value: "+1.25", label: "+1.25" },
          { value: "+1.00", label: "+1.00" },
          { value: "+0.75", label: "+0.75" },
          { value: "+0.50", label: "+0.50" },
          { value: "+0.25", label: "+0.25" },
          { value: "-0.25", label: "-0.25" },
          { value: "-0.50", label: "-0.50" },
          { value: "-0.75", label: "-0.75" },
          { value: "-1.00", label: "-1.00" },
          { value: "-1.25", label: "-1.25" },
          { value: "-1.50", label: "-1.50" },
          { value: "-1.75", label: "-1.75" },
          { value: "-2.00", label: "-2.00" },
          { value: "-2.25", label: "-2.25" },
          { value: "-2.50", label: "-2.50" },
          { value: "-2.75", label: "-2.75" },
          { value: "-3.00", label: "-3.00" },
          { value: "-3.25", label: "-3.25" },
          { value: "-3.50", label: "-3.50" },
          { value: "-3.75", label: "-3.75" },
          { value: "-4.00", label: "-4.00" },
          { value: "-4.25", label: "-4.25" },
          { value: "-4.50", label: "-4.50" },
          { value: "-4.75", label: "-4.75" },
          { value: "-5.00", label: "-5.00" },
          { value: "-5.25", label: "-5.25" },
          { value: "-5.50", label: "-5.50" },
          { value: "-5.75", label: "-5.75" },
          { value: "-6.00", label: "-6.00" },
          { value: "-6.50", label: "-6.50" },
          { value: "-7.00", label: "-7.00" },
          { value: "-7.50", label: "-7.50" },
          { value: "-8.00", label: "-8.00" },
          { value: "-8.50", label: "-8.50" },
          { value: "-9.00", label: "-9.00" },
          { value: "-9.50", label: "-9.50" },
          { value: "-10.00", label: "-10.00" },
          { value: '-10.50', label: '-10.50' },
          { value: '-11.00', label: '-11.00' },
          { value: '-11.50', label: '-11.50' },
          { value: '-12.00', label: '-12.00' },

        ],
        base_curve_values: ['8.6'],
        diameter: '14.2',
      },
    "1765135908964": {
        eye_powers: [
          { value: "+6.00", label: "+6.00" },
          { value: "+5.75", label: "+5.75" },
          { value: "+5.50", label: "+5.50" },
          { value: "+5.25", label: "+5.25" },
          { value: "+5.00", label: "+5.00" },
          { value: "+4.75", label: "+4.75" },
          { value: "+4.50", label: "+4.50" },
          { value: "+4.25", label: "+4.25" },
          { value: "+4.00", label: "+4.00" },
          { value: "+3.75", label: "+3.75" },
          { value: "+3.50", label: "+3.50" },
          { value: "+3.25", label: "+3.25" },
          { value: "+3.00", label: "+3.00" },
          { value: "+2.75", label: "+2.75" },
          { value: "+2.50", label: "+2.50" },
          { value: "+2.25", label: "+2.25" },
          { value: "+2.00", label: "+2.00" },
          { value: "+1.75", label: "+1.75" },
          { value: "+1.50", label: "+1.50" },
          { value: "+1.25", label: "+1.25" },
          { value: "+1.00", label: "+1.00" },
          { value: "+0.75", label: "+0.75" },
          { value: "+0.50", label: "+0.50" },
          { value: "+0.25", label: "+0.25" },
          { value: '0', label: '0' },
          { value: "-0.25", label: "-0.25" },
          { value: "-0.50", label: "-0.50" },
          { value: "-0.75", label: "-0.75" },
          { value: "-1.00", label: "-1.00" },
          { value: "-1.25", label: "-1.25" },
          { value: "-1.50", label: "-1.50" },
          { value: "-1.75", label: "-1.75" },
          { value: "-2.00", label: "-2.00" },
          { value: "-2.25", label: "-2.25" },
          { value: "-2.50", label: "-2.50" },
          { value: "-2.75", label: "-2.75" },
          { value: "-3.00", label: "-3.00" },
          { value: "-3.25", label: "-3.25" },
          { value: "-3.50", label: "-3.50" },
          { value: "-3.75", label: "-3.75" },
          { value: "-4.00", label: "-4.00" },
          { value: "-4.25", label: "-4.25" },
          { value: "-4.50", label: "-4.50" },
          { value: "-4.75", label: "-4.75" },
          { value: "-5.00", label: "-5.00" },
          { value: "-5.25", label: "-5.25" },
          { value: "-5.50", label: "-5.50" },
          { value: "-5.75", label: "-5.75" },
          { value: "-6.00", label: "-6.00" },
          { value: "-6.50", label: "-6.50" },
          { value: "-7.00", label: "-7.00" },
          { value: "-7.50", label: "-7.50" },
          { value: "-8.00", label: "-8.00" },
          { value: "-8.50", label: "-8.50" },
          { value: "-9.00", label: "-9.00" },
          { value: "-9.50", label: "-9.50" },
          { value: "-10.00", label: "-10.00" },
          { value: '-10.50', label: '-10.50' },
          { value: '-11.00', label: '-11.00' },
          { value: '-11.50', label: '-11.50' },
          { value: '-12.00', label: '-12.00' },

        ],
        base_curve_values: ['8.5'],
        diameter: '14.2',
      },
    '3341501268068':
      // Bausch + Lomb ULTRA for Astigmatism 6pk
      {
        eye_powers: [
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' }
        ],

        base_curve_values: [{ value: '8.6', label: '8.6' }],

        diameter: '14.5',
        cylinder: [
          { value: '-0.75', label: '-0.75' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.75', label: '-2.75' },
        ],
        axis: [
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '40', label: '40' },
          { value: '50', label: '50' },
          { value: '60', label: '60' },
          { value: '70', label: '70' },
          { value: '80', label: '80' },
          { value: '90', label: '90' },
          { value: '100', label: '100' },
          { value: '110', label: '110' },
          { value: '120', label: '120' },
          { value: '130', label: '130' },
          { value: '140', label: '140' },
          { value: '150', label: '150' },
          { value: '160', label: '160' },
          { value: '170', label: '170' },
          { value: '180', label: '180' },
        ],
      },
    '3341054640228':
      {
        // Bausch + Lomb ULTRA for Presbyopia 6pk
        eye_powers: [
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.25', label: '-6.25' },
          { value: '-6.50', label: '-6.50' },
          { value: '-6.75', label: '-6.75' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.25', label: '-7.25' },
          { value: '-7.50', label: '-7.50' },
          { value: '-7.75', label: '-7.75' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.25', label: '-8.25' },
          { value: '-8.50', label: '-8.50' },
          { value: '-8.75', label: '-8.75' },
          { value: '-9.00', label: '-9.00' },
          { value: '-9.25', label: '-9.25' },
          { value: '-9.50', label: '-9.50' },
          { value: '-9.75', label: '-9.75' },
          { value: '-10.00', label: '-10.00' }

        ],
        base_curve_values: [
          { value: '8.5', label: '8.5' },
        ],
        add_power: [
          { value: '1.50/low', label: '1.50/low' },
          { value: '2.50/high', label: '2.50/high' },
        ],
        diameter: '14.2'
      },
    '7430125551716':
      // Acuvue Oasys Max 1-Day 90pk
      {
        eye_powers: [
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' },
          { value: '-9.50', label: '-9.50' },
          { value: '-10.00', label: '-10.00' },
          { value: '-10.50', label: '-10.50' },
          { value: '-11.00', label: '-11.00' },
          { value: '-11.50', label: '-11.50' },
          { value: '-12.00', label: '-12.00' }
        ],
        base_curve_values: [
          { value: '8.5', label: '8.5' },
        ],
        diameter: '14.3',
      },
    '3344340287588':
      // 1-Day Acuvue Moist for Astigmatism 30pk
      {
        eye_powers: [
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.50', label: '-6.50' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.50', label: '-7.50' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.50', label: '-8.50' },
          { value: '-9.00', label: '-9.00' },
        ],

        base_curve_values: [{ value: '8.5', label: '8.5' }],

        diameter: '14.5',
        cylinder: [
          { value: '-0.75', label: '-0.75' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.25', label: '-2.25' },
        ],
        axis: [
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '40', label: '40' },
          { value: '50', label: '50' },
          { value: '60', label: '60' },
          { value: '70', label: '70' },
          { value: '80', label: '80' },
          { value: '90', label: '90' },
          { value: '100', label: '100' },
          { value: '110', label: '110' },
          { value: '120', label: '120' },
          { value: '130', label: '130' },
          { value: '140', label: '140' },
          { value: '150', label: '150' },
          { value: '160', label: '160' },
          { value: '170', label: '170' },
          { value: '180', label: '180' },
        ],
      },
    '7430134497380':
      {
        // Acuvue Oasys Max 1-Day Multifocal 30pk
        eye_powers: [
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.25', label: '-6.25' },
          { value: '-6.50', label: '-6.50' },
          { value: '-6.75', label: '-6.75' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.25', label: '-7.25' },
          { value: '-7.50', label: '-7.50' },
          { value: '-7.75', label: '-7.75' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.25', label: '-8.25' },
          { value: '-8.50', label: '-8.50' },
          { value: '-8.75', label: '-8.75' },
          { value: '-9.00', label: '-9.00' }
        ],
        base_curve_values: [
          { value: '8.4', label: '8.4' },
        ],
        add_power: [
          { value: '1.25/low', label: '1.25/low' },
          { value: '1.75/mid', label: '1.75/mid' },
          { value: '2.50/high', label: '2.50/high' },
        ],
        diameter: '14.3'
      },
    '6883191193700':
      {
        // Acuvue Oasys Max 1-Day Multifocal 30pk
        eye_powers: [
          { value: '+6.00', label: '+6.00' },
          { value: '+5.75', label: '+5.75' },
          { value: '+5.50', label: '+5.50' },
          { value: '+5.25', label: '+5.25' },
          { value: '+5.00', label: '+5.00' },
          { value: '+4.75', label: '+4.75' },
          { value: '+4.50', label: '+4.50' },
          { value: '+4.25', label: '+4.25' },
          { value: '+4.00', label: '+4.00' },
          { value: '+3.75', label: '+3.75' },
          { value: '+3.50', label: '+3.50' },
          { value: '+3.25', label: '+3.25' },
          { value: '+3.00', label: '+3.00' },
          { value: '+2.75', label: '+2.75' },
          { value: '+2.50', label: '+2.50' },
          { value: '+2.25', label: '+2.25' },
          { value: '+2.00', label: '+2.00' },
          { value: '+1.75', label: '+1.75' },
          { value: '+1.50', label: '+1.50' },
          { value: '+1.25', label: '+1.25' },
          { value: '+1.00', label: '+1.00' },
          { value: '+0.75', label: '+0.75' },
          { value: '+0.50', label: '+0.50' },
          { value: '+0.25', label: '+0.25' },
          { value: '0', label: '0' },
          { value: '-0.25', label: '-0.25' },
          { value: '-0.50', label: '-0.50' },
          { value: '-0.75', label: '-0.75' },
          { value: '-1.00', label: '-1.00' },
          { value: '-1.25', label: '-1.25' },
          { value: '-1.50', label: '-1.50' },
          { value: '-1.75', label: '-1.75' },
          { value: '-2.00', label: '-2.00' },
          { value: '-2.25', label: '-2.25' },
          { value: '-2.50', label: '-2.50' },
          { value: '-2.75', label: '-2.75' },
          { value: '-3.00', label: '-3.00' },
          { value: '-3.25', label: '-3.25' },
          { value: '-3.50', label: '-3.50' },
          { value: '-3.75', label: '-3.75' },
          { value: '-4.00', label: '-4.00' },
          { value: '-4.25', label: '-4.25' },
          { value: '-4.50', label: '-4.50' },
          { value: '-4.75', label: '-4.75' },
          { value: '-5.00', label: '-5.00' },
          { value: '-5.25', label: '-5.25' },
          { value: '-5.50', label: '-5.50' },
          { value: '-5.75', label: '-5.75' },
          { value: '-6.00', label: '-6.00' },
          { value: '-6.25', label: '-6.25' },
          { value: '-6.50', label: '-6.50' },
          { value: '-6.75', label: '-6.75' },
          { value: '-7.00', label: '-7.00' },
          { value: '-7.25', label: '-7.25' },
          { value: '-7.50', label: '-7.50' },
          { value: '-7.75', label: '-7.75' },
          { value: '-8.00', label: '-8.00' },
          { value: '-8.25', label: '-8.25' },
          { value: '-8.50', label: '-8.50' },
          { value: '-8.75', label: '-8.75' },
          { value: '-9.00', label: '-9.00' }
        ],
        base_curve_values: [
          { value: '8.4', label: '8.4' },
        ],
        add_power: [
          { value: '1.25/low', label: '1.25/low' },
          { value: '1.75/mid', label: '1.75/mid' },
          { value: '2.50/high', label: '2.50/high' },
        ],
        diameter: '14.3'
      },




  "skyhy": {
    eye_powers: [
      { value: "+6.00", label: "+6.00" },
      { value: "+5.50", label: "+5.50" },
      { value: "+5.00", label: "+5.00" },
      { value: "+4.50", label: "+4.50" },
      { value: "+4.00", label: "+4.00" },
      { value: "+3.75", label: "+3.75" },
      { value: "+3.50", label: "+3.50" },
      { value: "+3.25", label: "+3.25" },
      { value: "+3.00", label: "+3.00" },
      { value: "+2.75", label: "+2.75" },
      { value: "+2.50", label: "+2.50" },
      { value: "+2.25", label: "+2.25" },
      { value: "+2.00", label: "+2.00" },
      { value: "+1.75", label: "+1.75" },
      { value: "+1.50", label: "+1.50" },
      { value: "+1.25", label: "+1.25" },
      { value: "+1.00", label: "+1.00" },
      { value: "+0.75", label: "+0.75" },
      { value: "+0.50", label: "+0.50" },
      { value: "-0.50", label: "-0.50" },
      { value: "-0.75", label: "-0.75" },
      { value: "-1.00", label: "-1.00" },
      { value: "-1.25", label: "-1.25" },
      { value: "-1.50", label: "-1.50" },
      { value: "-1.75", label: "-1.75" },
      { value: "-2.00", label: "-2.00" },
      { value: "-2.25", label: "-2.25" },
      { value: "-2.50", label: "-2.50" },
      { value: "-2.75", label: "-2.75" },
      { value: "-3.00", label: "-3.00" },
      { value: "-3.25", label: "-3.25" },
      { value: "-3.50", label: "-3.50" },
      { value: "-3.75", label: "-3.75" },
      { value: "-4.00", label: "-4.00" },
      { value: "-4.25", label: "-4.25" },
      { value: "-4.50", label: "-4.50" },
      { value: "-4.75", label: "-4.75" },
      { value: "-5.00", label: "-5.00" },
      { value: "-5.25", label: "-5.25" },
      { value: "-5.50", label: "-5.50" },
      { value: "-5.75", label: "-5.75" },
      { value: "-6.00", label: "-6.00" },
      { value: "-6.50", label: "-6.50" },
      { value: "-7.00", label: "-7.00" },
      { value: "-7.50", label: "-7.50" },
      { value: "-8.00", label: "-8.00" },
      { value: "-8.50", label: "-8.50" },
      { value: "-9.00", label: "-9.00" },
      { value: "-9.50", label: "-9.50" },
      { value: "-10.00", label: "-10.00" }

    ],
    base_curve_values: ['8.7'],
    diameter: '14.1',
  },
  "hydro": {
    eye_powers: [
      { value: "+4.00", label: "+4.00" },
      { value: "+3.75", label: "+3.75" },
      { value: "+3.50", label: "+3.50" },
      { value: "+3.25", label: "+3.25" },
      { value: "+3.00", label: "+3.00" },
      { value: "+2.75", label: "+2.75" },
      { value: "+2.50", label: "+2.50" },
      { value: "+2.25", label: "+2.25" },
      { value: "+2.00", label: "+2.00" },
      { value: "+1.75", label: "+1.75" },
      { value: "+1.50", label: "+1.50" },
      { value: "+1.25", label: "+1.25" },
      { value: "+1.00", label: "+1.00" },
      { value: "+0.75", label: "+0.75" },
      { value: "+0.50", label: "+0.50" },
      { value: "-0.50", label: "-0.50" },
      { value: "-0.75", label: "-0.75" },
      { value: "-1.00", label: "-1.00" },
      { value: "-1.25", label: "-1.25" },
      { value: "-1.50", label: "-1.50" },
      { value: "-1.75", label: "-1.75" },
      { value: "-2.00", label: "-2.00" },
      { value: "-2.25", label: "-2.25" },
      { value: "-2.50", label: "-2.50" },
      { value: "-2.75", label: "-2.75" },
      { value: "-3.00", label: "-3.00" },
      { value: "-3.25", label: "-3.25" },
      { value: "-3.50", label: "-3.50" },
      { value: "-3.75", label: "-3.75" },
      { value: "-4.00", label: "-4.00" },
      { value: "-4.25", label: "-4.25" },
      { value: "-4.50", label: "-4.50" },
      { value: "-4.75", label: "-4.75" },
      { value: "-5.00", label: "-5.00" },
      { value: "-5.25", label: "-5.25" },
      { value: "-5.50", label: "-5.50" },
      { value: "-5.75", label: "-5.75" },
      { value: "-6.00", label: "-6.00" },
      { value: "-6.50", label: "-6.50" },
      { value: "-7.00", label: "-7.00" },
      { value: "-7.50", label: "-7.50" },
      { value: "-8.00", label: "-8.00" },
      { value: "-8.50", label: "-8.50" },
      { value: "-9.00", label: "-9.00" },
      { value: "-9.50", label: "-9.50" },
      { value: "-10.00", label: "-10.00" }
    ],
    base_curve_values: ['8.4'],
    diameter: '14.2',
  },
  default: {
    eye_powers: [
      { value: '+6.00', label: '+6.00' },
      { value: '+5.50', label: '+5.50' },
      { value: '+5.00', label: '+5.00' },
      { value: '+4.50', label: '+4.50' },
      { value: '+4.00', label: '+4.00' },
      { value: '+3.75', label: '+3.75' },
      { value: '+3.50', label: '+3.50' },
      { value: '+3.25', label: '+3.25' },
      { value: '+3.00', label: '+3.00' },
      { value: '+2.75', label: '+2.75' },
      { value: '+2.50', label: '+2.50' },
      { value: '+2.25', label: '+2.25' },
      { value: '+2.00', label: '+2.00' },
      { value: '+1.75', label: '+1.75' },
      { value: '+1.50', label: '+1.50' },
      { value: '+1.25', label: '+1.25' },
      { value: '+1.00', label: '+1.00' },
      { value: '+0.75', label: '+0.75' },
      { value: '+0.50', label: '+0.50' },
      { value: '-0.50', label: '-0.50' },
      { value: '-0.75', label: '-0.75' },
      { value: '-1.00', label: '-1.00' },
      { value: '-1.25', label: '-1.25' },
      { value: '-1.50', label: '-1.50' },
      { value: '-1.75', label: '-1.75' },
      { value: '-2.00', label: '-2.00' },
      { value: '-2.25', label: '-2.25' },
      { value: '-2.50', label: '-2.50' },
      { value: '-2.75', label: '-2.75' },
      { value: '-3.00', label: '-3.00' },
      { value: '-3.25', label: '-3.25' },
      { value: '-3.50', label: '-3.50' },
      { value: '-3.75', label: '-3.75' },
      { value: '-4.00', label: '-4.00' },
      { value: '-4.25', label: '-4.25' },
      { value: '-4.50', label: '-4.50' },
      { value: '-4.75', label: '-4.75' },
      { value: '-5.00', label: '-5.00' },
      { value: '-5.25', label: '-5.25' },
      { value: '-5.50', label: '-5.50' },
      { value: '-5.75', label: '-5.75' },
      { value: '-6.00', label: '-6.00' },
      { value: '-6.50', label: '-6.50' },
      { value: '-7.00', label: '-7.00' },
      { value: '-7.50', label: '-7.50' },
      { value: '-8.00', label: '-8.00' },
      { value: '-8.50', label: '-8.50' },
      { value: '-9.00', label: '-9.00' },
      { value: '-9.50', label: '-9.50' },
      { value: '-10.00', label: '-10.00' },
      { value: '-10.50', label: '-10.50' },
      { value: '-11.00', label: '-11.00' },
      { value: '-11.50', label: '-11.50' },
      { value: '-12.00', label: '-12.00' },
    ],

    base_curve_values: ['8.6'],
    diameter: '14.2',
  },
};
